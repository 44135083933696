import React, { useEffect, useRef } from "react";
import image from "./Source_FULL_BODY.png";
import styles from "./Orientation.module.css";
import { Typography } from "@mui/material";
import { useApp, AppContextType } from "../../providers/AppProvider";

function Orientation() {
  const { setButtonsDisabled } = useApp() as AppContextType;

  const imageWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOrientation = (event: DeviceOrientationEvent) => {
      if (imageWrapperRef.current) {
        const { alpha, beta, gamma } = event;
        const transform = `rotateX(${beta}deg) rotateY(${gamma}deg) rotateZ(${alpha}deg)`;
        imageWrapperRef.current.style.transform = transform;
      }
    };

    window.addEventListener("deviceorientation", handleOrientation);
    setButtonsDisabled(false);
    return () => {
      window.removeEventListener("deviceorientation", handleOrientation);
    };
  }, []);

  return (
    <>
      <div className={styles.content_wrapper}>
        <div ref={imageWrapperRef} className={styles.image_wrapper}>
          <img src={image} style={{ width: "100%", height: "100%" }} />
        </div>
      </div>
      <Typography>Draait de afbeelding als u uw apparaat draait?</Typography>
    </>

  );
}

export default Orientation;