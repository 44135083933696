import React, { useState } from "react";
import { Button } from "@mui/material";
import MobileStepper from "@mui/material/MobileStepper";
import { Media, Geolocation, Vibration, Storage, Orientation, Payment, PNotification, Multitouch, Contact, Tutorial } from "../tests/TestsLibrary";
import styles from "./MainPage.module.css";
import { useApp, AppContextType } from "../providers/AppProvider";
import { blue } from "@mui/material/colors";

const headerStyle = {
  width: "100vw",
  padding: "1rem 0",
  backgroundColor: blue[500],
  color: "white",
  transform: "translateX(-10vw)",
  marginBottom: "1rem",
  textAlign: "center" as const,
};

function MainPage() {
  const [currentStep, setCurrentStep] = useState(0);
  const { buttonsDisabled, setButtonsDisabled, handleUpdateRapport } = useApp() as AppContextType;

  const handleNext = (result: boolean): void => {
    if (currentStep + 1 < maxSteps) {
      setCurrentStep(currentStep + 1);
    }
    if (currentStep > 0) {
      // add result to rapport
      handleUpdateRapport(tests[currentStep].title, result);
    }
    setButtonsDisabled(true);
  };

  return (
    <>
      <div /*test*/ className={styles.test_wrapper}>
        {tests.map((test, index) => {
          return (
            index === currentStep &&
            <div key={index} style={{}}>
              <div style={headerStyle}>
                <h1>{test.title}</h1>
              </div>
              {test.component}
            </div>
          );
        })}
      </div>

      <div /*buttons*/>
        <MobileStepper
          steps={maxSteps}
          activeStep={currentStep}
          variant="dots"
          nextButton={<Button disabled={buttonsDisabled} variant="contained" color="success" onClick={() => handleNext(true)} >Werkt</Button>}
          backButton={<Button disabled={buttonsDisabled} variant="contained" color="error" onClick={() => handleNext(false)} >Werkt niet</Button>} />
      </div>
    </>
  );
}

export default MainPage;

const tests = [
  { title: "Tutorial", component: <Tutorial /> },
  { title: "Media capture", component: <Media /> },
  { title: "Geolocation", component: <Geolocation /> },
  { title: "Vibration", component: <Vibration /> },
  { title: "Storage", component: <Storage /> },
  { title: "Orientation", component: <Orientation /> },
  { title: "Payment", component: <Payment /> },
  { title: "Noticiation", component: <PNotification /> },
  { title: "Multitouch", component: <Multitouch /> },
  { title: "Contact", component: <Contact /> },
];

const maxSteps = tests.length;