import { Button, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useApp, AppContextType } from "../../providers/AppProvider";
import { red } from "@mui/material/colors";

function Contact() {
  const [contacts, setContacts] = useState<{ name: string, tel: string }[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { setButtonsDisabled } = useApp() as AppContextType;

  const getContacts = async () => {
    try {
      const supported = "contacts" in navigator && "ContactsManager" in window;
      if (supported) {
        const selectedContacts = await (navigator as any).contacts.select(["name", "tel"], { multiple: true }); // eslint-disable-line
        if (!selectedContacts.length) {
          // No contacts were selected in the picker.
          return;
        }
        setContacts(selectedContacts);
        setButtonsDisabled(false);
      }
      else {
        setErrorMessage("The 'Contacts' API is not supported in this browser.");
        setButtonsDisabled(false);
      }
    } catch (err) {
      console.error(err);
      setButtonsDisabled(false);
    }
  };

  function formatPhoneNumber(phoneNumber: string): string {
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");
    if (cleanedPhoneNumber.length === 9) {
      const formattedPhoneNumber = cleanedPhoneNumber.replace(
        /(\d{3})(\d{2})(\d{2})(\d{2})/,
        "+$1 $2 $3 $4"
      );
      return formattedPhoneNumber;
    }
    return phoneNumber;
  }

  return (
    <>
      <Stepper>
        <Step>
          <StepLabel>Contacten selecteren</StepLabel>
          <StepContent>
            <Typography>
              Klik op de knop om contacten te selecteren. (Het is enkel om de contacten weer te geven en er zal niemand gecontacteerd worden.)
            </Typography>
            <Button variant="contained" onClick={getContacts}>Contact</Button>
          </StepContent>
        </Step>
      </Stepper>
      <Typography>
        {errorMessage.length > 0 && <span style={{
          color: red[500]
        }}>{`Er ging iets mis: ${errorMessage}`}</span>}
      </Typography>
      {
        contacts.map((contact, index) => <p key={index}>{contact.name} - {contact.tel.length > 0 ? formatPhoneNumber(contact.tel[0]) : "/"}</p>)
      }
    </>
  );
}

export default Contact;