import { Box, Button, Step, StepContent, StepLabel, Typography } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import React, { useEffect, useRef, useState } from "react";
import { useApp, AppContextType } from "../../providers/AppProvider";

function Media() {
  const [step, setStep] = useState(0);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [error, setError] = useState<string>("");

  const { setButtonsDisabled } = useApp() as AppContextType;

  const videoRef = useRef<HTMLVideoElement>(null);

  const requestCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setMediaStream(stream);
      setStep(1);
      setButtonsDisabled(false);
    } catch (error) {
      console.error("Error accessing camera:", error);
      setButtonsDisabled(false);
      setStep(1);
      setError("Error accessing camera");
    }
  };

  useEffect(() => {
    if (mediaStream) {
      const videoElement = videoRef.current;
      if (videoElement) {
        videoElement.srcObject = mediaStream;
      }
    }
  }, [mediaStream]);

  return (
    <>
      <Stepper activeStep={step} orientation="vertical">
        <Step>
          <StepLabel error={error.length > 0}>Toestemming camera</StepLabel>
          <StepContent>
            <Typography>
              Toestemming is vereist om toegang te krijgen tot de camera.
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Button onClick={requestCameraPermission} variant="contained" color="primary">
                Toestemming vragen
              </Button>
            </Box>
          </StepContent>
        </Step>
        <Step>
          <StepLabel error={error.length > 0}>Foto nemen</StepLabel>
          <StepContent>
            {error.length > 0 && <Typography color="error">{error}</Typography>}
            {mediaStream && (
              <Box sx={{ position: "relative", mt: 2 }}>
                <video ref={videoRef} autoPlay muted style={{ width: "100%" }} />
              </Box>
            )}
            {error.length === 0 && <Typography>
              Kan u uzelf zien? Dan is de camera correct geactiveerd.
            </Typography>}

          </StepContent>
        </Step>
      </Stepper>
    </>
  );
}

export default Media;
