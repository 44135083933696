// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter, Route, Routes, } from "react-router-dom";
import ThankYouPage from "src/pages/ThankYouPage";
import { AppProvider } from "./providers/AppProvider";
import ReactPWAInstallProvider from "react-pwa-install";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);


root.render(
  <React.StrictMode>
    <ReactPWAInstallProvider enableLogging={true}>
      <BrowserRouter>
        <AppProvider>
          <Routes>
            <Route path="/" element={<App />}></Route>
            <Route path="/thank-you" element={<ThankYouPage />}></Route>
          </Routes>
        </AppProvider>
      </BrowserRouter>
    </ReactPWAInstallProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();