import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useApp, AppContextType } from "../../providers/AppProvider";

function Multitouch() {
  const [numberOfTouches, setNumberOfTouches] = useState(0);

  const { setButtonsDisabled } = useApp() as AppContextType;

  const handleTouches = (event: TouchEvent) => {
    setNumberOfTouches(event.touches.length);
  };

  useEffect(() => {
    window.addEventListener("touchstart", handleTouches);
    setButtonsDisabled(false);
    return () => {
      window.removeEventListener("touchstart", handleTouches);
    };
  }, []);

  return (
    <>
      <Typography><b>Raak met enkele vingers het scherm aan.</b></Typography>
      <Typography>{`Hoeveelheid vingers die het scherm aanraken: ${numberOfTouches}`}</Typography>
    </>
  );
}

export default Multitouch;