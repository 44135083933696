import React from "react";

function ThankYouPage() {
  return (
    <div style={{ textAlign: "center" }}>
      <h1>Bedankt om deel te nemem!</h1>
      <p>Je mag de app nu verwijderen.</p>
    </div>
  );
}

export default ThankYouPage;