import { Button, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useApp, AppContextType } from "../../providers/AppProvider";

function Payment() {
  const [step, setStep] = useState(0);
  const [paymentFailed, setPaymentFailed] = useState(false);

  const { setButtonsDisabled } = useApp() as AppContextType;

  const handlePayment = async () => {
    const paymentMethods = [{
      supportedMethods: "https://bobbucks.dev/pay",
    }];

    const details = {
      id: "bp-payment-example",
      displayItems: [
        {
          label: "Original donation amount",
          amount: {
            currency: "EUR",
            value: "0.01"
          }
        },
        {
          label: "Friends and family discount",
          amount: {
            currency: "EUR",
            value: "-0.01"
          }
        }
      ],
      total: {
        label: "Total",
        amount: {
          currency: "EUR",
          value: "0"
        }
      }
    };

    const request = new PaymentRequest(paymentMethods, details);

    request.canMakePayment().then(result => {
      if (result) {
        // This browser supports the specified payment method.
      } else {
        setPaymentFailed(true);
        setStep(2);
      }
    }).catch(e => {
      console.error("Error calling canMakePayment: ", e);
    });

    request.show().then(response => {
      // [process payment]
      // send to a PSP etc.
      response.complete("success");
      setStep(2);
    });

    setButtonsDisabled(false);
  };

  return (
    <>
      <Stepper activeStep={step} orientation="vertical">
        <Step>
          <StepLabel>U wordt niets aangerekend</StepLabel>
          <StepContent>
            <Typography>
              Deze betaling is een demo. U wordt <b>niets</b> aangerekend.
              U zal een betalingsverzoek ontvangen van <b>50</b> EURO en zal betalen met <b>&quot;BOB bucks&quot;</b>. Dit heeft <b>geen waarde</b> en wordt louter gebruikt voor demonstratieve doeleinden.
            </Typography>
            <Button variant="contained" color="success" onClick={() => setStep(1)}>Akkoord</Button>
          </StepContent>
        </Step>
        <Step>
          <StepLabel error={paymentFailed}>Betaal Demo</StepLabel>
          <StepContent>
            <Button variant="contained" onClick={handlePayment}>Betalen</Button>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Betaling afgerond</StepLabel>
          <StepContent>
            {paymentFailed && <Typography color="red">Betaling mislukt</Typography>}
            {!paymentFailed && <Typography color="green">Betaling gelukt</Typography>}
          </StepContent>
        </Step>
      </Stepper>


    </>
  );
}

export default Payment;
