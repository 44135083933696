import React, { useEffect, useState } from "react";
import { Button, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import { useApp, AppContextType } from "../providers/AppProvider";
import { green, red } from "@mui/material/colors";
import { useReactPWAInstall } from "react-pwa-install";
import logoImage from "./512LogoWolf.png";

function Tutorial() {
  const [step, setStep] = React.useState(0);
  const { setButtonsDisabled, handleUpdateRapport } = useApp() as AppContextType;
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handleSetStep = (step: number) => {
    setStep(step);
  };

  const performAllApiTests = () => {
    const apiResults = {
      barcodeApi: false,
      webAuthApi: false,
      wakeLockApi: false,
      nfcApi: false,
      bluetoothApi: false,
      backgroundSyncApi: false,
      backgroundFetchApi: false,
    };
    //barcode api
    if ("BarcodeDetector" in globalThis) { apiResults.barcodeApi = true; }
    //web auth api
    if (!(typeof (PublicKeyCredential) == "undefined")) { apiResults.webAuthApi = true; }
    //wake lock api
    if ("wakeLock" in navigator) { apiResults.wakeLockApi = true; }
    //nfc api
    if ("NDEFReader" in globalThis) { apiResults.nfcApi = true; }
    //bluetooth api
    if ("Bluetooth" in globalThis) { apiResults.bluetoothApi = true; }
    //background sync api
    if ("SyncManager" in globalThis) { apiResults.backgroundSyncApi = true; }
    //background fetch api
    if ("BackgroundFetchManager" in self) { apiResults.backgroundFetchApi = true; }
    handleUpdateRapport("apiResults", apiResults);
    setButtonsDisabled(false);
  };

  const handleInstallPWA = () => {
    pwaInstall({
      title: "Installatie",
      logo: logoImage,
      description: "Installeer de app om deel te nemen aan de testen.",
    }).catch(() => alert("User opted out from installing"));
  };

  useEffect(() => {
    if (isInstalled()) {
      handleSetStep(1);
    }
  }, []);

  //<InstallPWA handleSetStep={handleSetStep} />
  return (
    <>
      <Stepper activeStep={step} orientation="vertical" variant="elevation">
        <Step>
          <StepLabel>Installeer de app</StepLabel>
          <StepContent>
            {supported() && !isInstalled() && <Button onClick={handleInstallPWA} variant="contained">Installeer de app</Button>}

            <Typography>Open de app wanneer deze <b>geinstalleerd</b> is.</Typography>
            <Typography color={"GrayText"}>Als er geen installeerknop te zien is, druk op het label Installeer app</Typography>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Testen</StepLabel>
          <StepContent>
            <p>
              Wanneer er om toestemming gevraagd wordt, ga hier mee <b>akkoord</b><br />
              Betalingen zijn puur <b>fake</b> en er zal <b>niets</b> van uw rekening afgehaald worden. <br />
              Er worden enkele gegevens over je apparaat bijgehouden.
            </p>
            <p>
              Deze gegevens worden achterliggend bijgehouden: <br />
              <ol>
                <li>Besturingssysteem (IOS of Android)</li>
                <li>Browser (Chrome, Safari, ...)</li>
                <li>Browser versie (bv. versie 1.09)</li>
                <li>Testresultaten</li>
              </ol>
            </p>
            <Button variant="contained" onClick={handleNext}>Volgende</Button>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Test voltooid</StepLabel>
          <StepContent>
            <p>Wanneer de test voltooid is zullen onderstaande knoppen geactiveerd worden.</p>
            <p>Hierbij zal u moeten aangeven of het <span style={{ color: green[500] }} > werkte</span> of <span style={{ color: red[500] }}>niet</span></p>
            <Button variant="contained" onClick={performAllApiTests}>Ik begrijp het</Button>
          </StepContent>
        </Step>
      </Stepper>
    </>
  );
}

export default Tutorial;