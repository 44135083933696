import { Button, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useApp, AppContextType } from "../../providers/AppProvider";

function Vibration() {
  const [step, setStep] = useState(0);

  const { setButtonsDisabled } = useApp() as AppContextType;

  const handleVibrate = () => {
    try {
      navigator.vibrate(300);
    } catch (error) {
      console.error(error);
    } finally {
      setStep(1);
    }
  };

  const handleVibratePattern = () => {
    try {
      navigator.vibrate([200, 100, 200]);
    } catch (error) {
      console.error(error);
    } finally {
      setStep(2);
      setButtonsDisabled(false);
    }
  };

  return (
    <>
      <Stepper activeStep={step} orientation="vertical">
        <Step>
          <StepLabel>Vibreer</StepLabel>
          <StepContent>
            <Button variant="contained" onClick={handleVibrate}>Vibreer</Button>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Vibreer patroon</StepLabel>
          <StepContent>
            <Button variant="contained" onClick={handleVibratePattern}>Vibreer patroon</Button>
          </StepContent>
        </Step>
        <Step>
          <StepLabel></StepLabel>
          <StepContent>
            <Typography>Vibreerde je gsm?</Typography>
          </StepContent>
        </Step>
      </Stepper>
    </>
  );
}

export default Vibration;