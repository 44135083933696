import React, { useState } from "react";
import { openDB } from "idb";
import { Button, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import { useApp, AppContextType } from "../../providers/AppProvider";

type Brand = {
  name: string;
  age: number;
  id: number;
}

function Storage() {
  const [step, setStep] = useState(0);
  const [error, setError] = useState(5);
  const [data, setData] = useState<Brand[]>([]);

  const { setButtonsDisabled } = useApp() as AppContextType;

  const handleStorageButtonClick = async () => {
    try {
      const request = await openDB("PWABPDB", /* version number */);
      const version = request.version;
      request.close();
      const db = await openDB("PWABPDB", version + 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains("automerken")) {
            db.createObjectStore("automerken", { autoIncrement: true, keyPath: "id" });
          }
        },
      });

      db.close();

      setStep(1);
    } catch (error) {
      console.error("Error opening database");
      setError(0);
      setStep(3);
    }
  };
  const handleSaveDataButtonClick = async () => {
    try {
      const request = await openDB("PWABPDB", /* version number */);
      const version = request.version;
      console.log(version);
      request.close();
      const db = await openDB("PWABPDB");
      const transaction = db.transaction("automerken", "readwrite");
      const store = transaction.objectStore("automerken");

      await store.put({ name: "Volkswagen", age: 86, id: 1 });
      await store.put({ name: "Audi", age: 113, id: 2 });

      await transaction.oncomplete;
      db.close();

      setStep(2);
    } catch (error) {
      console.error("Error opening database", error);
      setError(1);
      setStep(3);
    }
  };

  const handleGetDataButtonClick = async () => {
    try {
      const db = await openDB("PWABPDB");
      const transaction = db.transaction("automerken", "readonly");
      const store = transaction.objectStore("automerken");

      const data = await store.getAll();
      setData(data);
      setButtonsDisabled(false);

      db.close();
    } catch (error) {
      console.error("Error opening database");
      setError(2);
      setStep(3);
      setButtonsDisabled(false);
    }
  };

  return (
    <div>
      <Stepper orientation="vertical" activeStep={step}>
        <Step>
          <StepLabel>Lokale databank aanmaken</StepLabel>
          <StepContent>
            <Button variant="contained" onClick={handleStorageButtonClick}>Databank aanmaken</Button>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Data opslaan</StepLabel>
          <StepContent>
            <Button variant="contained" onClick={handleSaveDataButtonClick}>Data opslaan</Button>
            <p>&#123; name: &quot;Volkswagen&quot;, age: 86&#125;</p>
            <p>&#123; name: &quot;Audi&quot;, age: 113&#125;</p>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Data ophalen</StepLabel>
          <StepContent>
            <Button variant="contained" onClick={handleGetDataButtonClick}>Data ophalen</Button>
            <div>{data.map((brand) => (
              <p key={brand.id}>&#123; name: &quot;{brand.name}&quot;, age: {brand.age}&#125;</p>
            ))}</div>
            {data.length > 0 && <Typography>Werd de data succesvol weergegeven?</Typography>}
          </StepContent>
        </Step>
        {error < 5 && <Step>
          <StepLabel></StepLabel>
          <StepContent>
            <Typography>Er is een fout opgetreden</Typography>
          </StepContent>
        </Step>}

      </Stepper>

    </div>
  );
}

export default Storage;