import React, { useState } from "react";
import { Button, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import { useApp, AppContextType } from "../../providers/AppProvider";

function Geolocation() {
  const [location, setLocation] = useState<{ latitude: number; longitude: number } | null>(null);
  const [step, setStep] = useState(0);

  const { setButtonsDisabled } = useApp() as AppContextType;

  const getMyLocation = () => {
    try {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude });
      });
    } catch (error) {
      console.error(error);
    } finally {
      setStep(1);
      setButtonsDisabled(false);
    }
  };

  return (
    <>
      <Stepper activeStep={step} orientation="vertical">
        <Step>
          <StepLabel>Toestemming locatie</StepLabel>
          <StepContent>
            <Typography>Voor apple gebruikers. Zorg ervoor dat locatie aan staat in: <b>instellingen &gt; privacy &gt; Locatievoorzieningen &gt; Safari-sites &gt; bij gebruik van de app</b></Typography>
            {location == null && <Button onClick={getMyLocation} variant="contained" color="primary">Locatie aanzetten</Button>}
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Kaart</StepLabel>
          <StepContent>
            {location && <iframe
              style={{ width: "100%", height: "500px" }}
              src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_MAPS_API_KEY}&q=${location.latitude},${location.longitude}`}
            >
            </iframe>}
            <Typography>Kan je de kaart zien met jouw locatie?</Typography>
          </StepContent>
        </Step>
      </Stepper>

    </>
  );
}

export default Geolocation;
