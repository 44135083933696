import { Button, Step, StepContent, StepLabel, Stepper, TextField } from "@mui/material";
import React, { useState } from "react";
import { useApp, AppContextType } from "../../providers/AppProvider";

function PNotification() {
  const [step, setStep] = useState(0);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");

  const { setButtonsDisabled } = useApp() as AppContextType;

  const requestNotificationPermission = async () => {
    try {
      Notification.requestPermission();
    } catch (error) {
      console.error(error);
    } finally {
      setStep(1);
    }
  };

  const handleSendNotification = async () => {
    setButtonsDisabled(false);

    navigator.serviceWorker.ready.then(function (registration) {
      registration.showNotification(title, {
        body: body,
      });
    });
  };

  return (
    <>
      <Stepper activeStep={step} orientation="vertical">
        <Step>
          <StepLabel>Toestemming geven</StepLabel>
          <StepContent>
            <Button variant="contained" color="primary" onClick={requestNotificationPermission}>Toestaan</Button>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Melding tonen</StepLabel>
          <StepContent>
            <TextField variant="filled" label="Titel" onChange={e => setTitle(e.target.value)} value={title} />
            <TextField variant="filled" label="Body" onChange={e => setBody(e.target.value)} value={body} />
            <Button variant="contained" onClick={handleSendNotification}>Verstuur</Button>
          </StepContent>
        </Step>

      </Stepper>
    </>
  );
}

export default PNotification;