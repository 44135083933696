import React, { createContext, useMemo, useContext, useState, useCallback, useEffect } from "react";
import platform from "platform";
import { db } from "src/lib/firebase";
import { addDoc, collection } from "firebase/firestore/lite";
import { useNavigate } from "react-router-dom";
import { openDB } from "idb";

export type AppContextType = {
  buttonsDisabled: boolean;
  rapport: object;
  setButtonsDisabled: (value: boolean) => void;
  handleUpdateRapport: (key: string, value: boolean | object) => void;
}

const AppContext = createContext<AppContextType | null>(null);

export const useApp = () => useContext(AppContext);

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(true);
  const [rapport, setRapport] = useState({
    platform_description: platform.description,
    platforn_name: platform.name,
    platform_version: platform.version,
    platform_os: platform.os?.family,
    platform_product: platform.product,
  });
  const [oneTimeSend, setOneTimeSend] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleUpdateRapport = useCallback((key: string, value: boolean | object): void => {
    if (typeof value === "boolean") {
      setRapport({ ...rapport, [key]: value });
    } else {
      //add all values from object to rapport
      const merged = { ...rapport, ...value };
      setRapport(merged);
    }
  }, [rapport]);

  useEffect(() => {
    //check if contact key in rapport
    if (Object.prototype.hasOwnProperty.call(rapport, "Contact") && !oneTimeSend) {
      setOneTimeSend(true);
      //send rapport to firebase
      try {
        addDoc(collection(db, "rapport"), rapport);
        handleMarkAsDone();
        navigate("/thank-you");
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    }
  }, [rapport]);

  useEffect(() => {
    const checkIfDone = async () => {
      const db = await openDB("PWABPDB");
      if (db.objectStoreNames.contains("finished")) {
        navigate("/thank-you");
      }
    };
    checkIfDone();
  }, []);

  const handleMarkAsDone = async () => {
    const request = await openDB("PWABPDB");
    const version = request.version;
    request.close();
    const db = await openDB("PWABPDB", version + 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains("finished")) {
          db.createObjectStore("finished", { autoIncrement: true, keyPath: "id" });
        }
      },
    });

    db.close();
  };

  const value = useMemo(() => ({
    buttonsDisabled,
    setButtonsDisabled,
    rapport,
    handleUpdateRapport,
  }), [
    buttonsDisabled,
    setButtonsDisabled,
    rapport,
    handleUpdateRapport,
  ]);


  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};